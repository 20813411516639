












































































import DealerDetail from '@/components/DealerDetail.vue';
import DealerDualMenu from '@/components/DealerDualMenu.vue';
import {
  initPagination,
  Pagination,
  updatePagination
} from '@/types/Pagination';
import {
  initTableSortAndFilter,
  updateTableSort,
  TableSortAndFilter
} from '@/types/TableSortAndFilter';
import SortableTable from '@/components/SortableTable.vue';
import {
  SortableTableDataConfig,
  SortableTableHeader
} from '@/types/SortableTableFormat';
import {
  cleanupFilterRequest,
  createFilterRequest
} from '@/types/api/FilterRequest';
import debounce from 'debounce';
import Vue from 'vue';
import {
  CustomerListDetailResponse,
  CustomerListRequest,
  CustomerListResponse
} from '@/types/api/CustomerList';
import ModalViewCustomer from '@/components/modals/ViewCustomer.vue';
import ModalConfirmation from '@/components/modals/Confirmation.vue';
import { AxiosResponse, AxiosError } from 'axios';
import { ApiResponse } from '@/types/api/Response';

const deleteCustomerConfirmationTitle = 'Delete Confirmation';
const deleteCustomerConfirmationMessage = '<p>Are you sure you want to delete this customer?</p><p>All warranties and products associated with this customer will be released.</p><p>This action cannot be undone!</p>';

const tableHeader = [
  {
    'key': 'customer.name',
    'display': 'Full Name',
    'width': 0,
    'cssClass': 'text-center',
    'isSortable': true,
    'sortOrder': 0,
    'isEmpty': false
  },
  {
    'key': 'customer.customerDetail.ic',
    'display': 'IC/Passport',
    'width': 200,
    'cssClass': 'text-center',
    'isSortable': true,
    'sortOrder': 0,
    'isEmpty': false
  },
  {
    'key': 'customer.phone',
    'display': 'Contact No.',
    'width': 250,
    'cssClass': 'text-center',
    'isSortable': true,
    'sortOrder': 0,
    'isEmpty': false
  },
  {
    'key': 'customer.email',
    'display': 'Email',
    'width': 0,
    'cssClass': 'text-center',
    'isSortable': true,
    'sortOrder': 0,
    'isEmpty': false
  },
  {
    'key': 'customer.createdBy.name',
    'display': 'Registered Dealer',
    'width': 260,
    'cssClass': 'text-center',
    'isSortable': true,
    'sortOrder': 0,
    'isEmpty': false
  },
  {
    'key': 'customer.createdAt',
    'display': 'Created Date',
    'width': 230,
    'cssClass': 'text-center',
    'isSortable': true,
    'sortOrder': 0,
    'isEmpty': false
  },
  {
    'key': 'status',
    'display': 'Status',
    'width': 240,
    'cssClass': 'text-center',
    'isSortable': true,
    'sortOrder': 0,
    'isEmpty': false
  },
  {
    'key': '',
    'display': '',
    'width': 100,
    'cssClass': '',
    'isSortable': false,
    'sortOrder': 0,
    'isEmpty': true
  }
] as Array<SortableTableHeader>;

const tableConfig = [
  {
    'cssClass': 'text-center text-nowrap',
    'hasDisplay': true,
    'buttons': []
  },
  {
    'cssClass': 'text-center',
    'hasDisplay': true,
    'buttons': []
  },
  {
    'cssClass': 'text-center',
    'hasDisplay': true,
    'buttons': []
  },
  {
    'cssClass': 'text-center',
    'hasDisplay': true,
    'buttons': []
  },
  {
    'cssClass': 'text-center',
    'hasDisplay': true,
    'buttons': []
  },
  {
    'cssClass': 'text-center',
    'hasDisplay': true,
    'buttons': [],
    'isDate': true,
    'dateTimeFormat': 'DD MMM YYYY'
  },
  {
    'cssClass': 'text-center text-uppercase font-weight-bold',
    'hasDisplay': true,
    'buttons': [],
    'classFn': (val) => {
      return val === 'REQUESTED' ? 'text-danger' : '';
    }
  },
  {
    'cssClass': 'text-nowrap',
    'hasDisplay': false,
    'buttons': [
      {
        'cssClass': 'btn-iris-table btn-iris-view',
        'event': 'view'
      }
    ]
  }
] as Array<SortableTableDataConfig>;

export default Vue.extend({
  'name': 'CustomerList',
  'components': {
    SortableTable,
    DealerDetail,
    DealerDualMenu,
    ModalViewCustomer,
    ModalConfirmation
  },
  'watch': {
    'tableFilter.search' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    },
    'pagination.page' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    }
  },
  data () {
    const pagination = initPagination();
    const tableFilter = initTableSortAndFilter();
    return {
      pagination,
      tableFilter,
      'tableHeader': [] as Array<SortableTableHeader>,
      'tableDataConfigs': [] as Array<SortableTableDataConfig>,
      'tableData': [] as Array<CustomerListDetailResponse>,
      'debounceGetList': null as unknown,
      'currentViewingCustomerId': null as number | null,
      'deleteCustomer': {
        'confirmationTitle': deleteCustomerConfirmationTitle,
        'confirmationMessage': deleteCustomerConfirmationMessage,
        'showConfirmButton': true,
        'discardButtonText': 'Discard',
        'customerId': -1
      }
    };
  },
  'computed': {
    isAdminViewDealer (): boolean {
      return this.$route.name === 'AdminDealerCustomerList';
    },
    isSuperAdminOrAdmin (): boolean {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    appStateCurrentViewingDealerId (): number {
      return this.$store.state.app.currentViewingDealerId;
    }
  },
  mounted () {
    if (this.isAdminViewDealer) {
      const dealerId =
        this.$route.query && this.$route.query.dealer
          ? parseInt(this.$route.query.dealer as string)
          : this.appStateCurrentViewingDealerId;
      if (dealerId !== this.appStateCurrentViewingDealerId) {
        this.$store.commit('updateViewDealerId', dealerId);
      }
      this.$router
        .push({
          'query': {
            'dealer': dealerId + ''
          }
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        });
    }

    this.debounceGetList = debounce(this.getList, 500);

    if (this.isSuperAdminOrAdmin) {
      // if its admin
      const lastColumnIndex = tableHeader.length - 1;

      const tableHeaderCopy = JSON.parse(JSON.stringify(tableHeader));
      const tableConfigCopy = JSON.parse(JSON.stringify(tableConfig));

      if (tableHeaderCopy[lastColumnIndex]) {
        (tableHeaderCopy[lastColumnIndex] as SortableTableHeader).width = 150;
      }

      if (tableConfigCopy[lastColumnIndex]) {
        (tableConfigCopy[lastColumnIndex] as SortableTableDataConfig).buttons!.push({
          'cssClass': 'btn-iris-table btn-iris-delete',
          'event': 'delete'
        });
      }
      this.tableHeader = tableHeaderCopy;
      this.tableDataConfigs = tableConfigCopy;
    } else {
      this.tableHeader = tableHeader;
      this.tableDataConfigs = tableConfig;
    }

    this.getList();
  },
  'methods': {
    getList () {
      let requestData = createFilterRequest(this.pagination, this.tableFilter);
      requestData = cleanupFilterRequest(requestData);

      let getRequest = null as unknown;
      if (this.isAdminViewDealer) {
        const getWarrantySealRequest: CustomerListRequest = Object.assign(
          {
            'dealerId': this.appStateCurrentViewingDealerId
          },
          requestData
        );

        getRequest = this.axios.get('customer/list', {
          'params': getWarrantySealRequest
        });
      } else {
        getRequest = this.axios.get('customer/list', {
          'params': requestData
        });
      }

      (getRequest as Promise<AxiosResponse<any>>).then(({ data }) => {
        const response = data as CustomerListResponse;
        this.tableData = response.result.items;

        const paginationUpdate = response.result as Pagination;
        updatePagination(this.pagination, paginationUpdate);
      });
    },
    updateTableSort (tableSort: TableSortAndFilter) {
      updateTableSort(this.tableFilter, tableSort);
      this.getList();
    },
    viewCustomer (rowNumber: number) {
      this.currentViewingCustomerId = this.tableData[rowNumber].id;

      (this.$refs.modalViewCustomer as any).show();
    },
    exportAll () {
      this.axios({
        'url': 'Customer/export',
        'method': 'GET',
        'responseType': 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'customers.xlsx'); // or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    promptDeleteCustomer (rowIndex: number) {
      this.deleteCustomer.customerId = this.tableData[rowIndex]
        ? this.tableData[rowIndex].id
        : 0;
      this.deleteCustomer.confirmationTitle = deleteCustomerConfirmationTitle;
      this.deleteCustomer.confirmationMessage = deleteCustomerConfirmationMessage;
      this.deleteCustomer.showConfirmButton = true;
      this.deleteCustomer.discardButtonText = 'Discard';
      (this.$refs.deleteCustomerConfirmation as any).show();
    },
    confirmDeleteCustomer () {
      (this.$refs.deleteCustomerConfirmation as any).hide(() => {
        this.axios.delete(`customer/${this.deleteCustomer.customerId}`).then(({ data }) => {
          this.getList();

          this.deleteCustomer.confirmationTitle = 'Success';
          this.deleteCustomer.confirmationMessage = data.message;
          this.deleteCustomer.showConfirmButton = false;
          this.deleteCustomer.discardButtonText = 'Dismiss';
        }).catch((err:AxiosError) => {
          this.deleteCustomer.confirmationTitle = 'Error';
          if (err && err.response) {
            const data = err.response.data as ApiResponse;
            this.deleteCustomer.confirmationMessage = data.message;
          }
          this.deleteCustomer.showConfirmButton = false;
          this.deleteCustomer.discardButtonText = 'Dismiss';
        }).finally(() => {
          (this.$refs.deleteUserConfirmation as any).show();
        });
      });
    },
    discardDeleteCustomer () {
      (this.$refs.deleteCustomerConfirmation as any).hide();
    }
  }

});
